import 'o365.modules.DataObject.extensions.ExportData.ts';
console.warn(`'o365.dataObject.extension.ExportData.ts' has been depricated, please import 'o365.modules.DataObject.extensions.ExportData.ts' instead`)

// import type { IExportConfig, ExportFormat } from 'o365.modules.exportData.ts';
// import DataObject from 'o365.modules.DataObject.ts';
// import { dataObjectExportData } from 'o365.modules.exportData.ts';

// declare module "o365.modules.DataObject.ts" {
//     interface DataObject<T> {
//         export: ExportData<T>;
//         exportData: typeof ExportData.prototype.exportData
//     }
// }

// Object.defineProperty(DataObject.prototype, 'export', {
//     get() {
//         if (this._export == null) {
//             this._export = new ExportData(this);
//         };
//         return this._export;
//     },
// });
// Object.defineProperty(DataObject.prototype, 'exportData', {
//     get() {
//         return this.export.exportData.bind(this.export);
//     },
// });

// export class ExportData<T extends object = any> {
//     private _dataObject: DataObject<T>;

//     private _defaultSheetName?: string;
//     private _exportFileName?: string;
//     private _exportDate?: Date;

//     constructor(pDataObject: DataObject) {
//         this._dataObject = pDataObject;
//     }

//     /** Sets the default sheet name for this dataobject export operations */
//     setDefaultSheetName(value?: string) {
//         this._defaultSheetName = value;
//     }
//     /** Override exported file name (without extension) */
//     setFileName(value?: string) {
//         this._exportFileName = value;
//     }
//     /** Set exported date time to show in title row */
//     setExportDate(value?: Date) {
//         this._exportDate = value;
//     }

//     getDefaultSheetName() {
//         return this._defaultSheetName;
//     }

//     getFileName() {
//         return this._exportFileName;
//     }

//     getExportDate = () => this._exportDate;

//     exportData(pExportConfig: IExportConfig, pRequestData: any, pFormat: ExportFormat): Promise<void> {
//         return dataObjectExportData(this._dataObject, pExportConfig, pRequestData, pFormat);
//     }
// }